const colors = {
  primaryMain: '#BF0B0B',
  primaryDark: '#870000',
  primaryLight: '#f94f36',
  secondaryMain: '#800606',
  secondaryDark: '#4f0000',
  secondaryLight: '#b6402f',
  errorMain: '#DC3B3B',
  errorDark: '#a30014',
  errorLight: '#ff7066',
  warningMain: '#F2B950',
  warningDark: '#bc891e',
  warningLight: '#ffeb80',
  textMain: '#000000',
  textDark: '#363636',
  textLight: '#1A1D3E',
  primaryBg: '#FFFFFF',
  secondaryBg: '#F0F0F0',
  disabled: '#C4C4C4',
  successMain: '#4CAF62',
  successLight: '#7fe290',
  successDark: '#0b7f36',
};

export { colors };
