import loadable from '@loadable/component';
import routes from 'config/routes';
import { useAuth } from 'hooks/AuthContext';
import NotFound from 'pages/NotFound';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IRoute } from 'types';
import withError from './HOCs/withError';
import withLoading from './HOCs/withLoading';

const Switcher = () => {
  const { user } = useAuth();

  const userIsAuthenticated = user !== undefined;

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route: IRoute) => {
          const componentRoute = route.component;
          const Component = loadable(() => import(`pages/${componentRoute}`));
          const EnhacedComponent = withLoading(withError(Component as any));

          if (route.permissions.length) {
            if (!userIsAuthenticated) return <></>;

            if (!route.permissions.includes(user.profileRole)) return <></>;
          }

          return (
            <Route
              key={route.name}
              path={route.path}
              element={<EnhacedComponent />}
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Switcher;
